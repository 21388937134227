<template>
    <!-- @click="" -->

    <div class="card-ticket">
        <div
            class="trang-thai"
            :style="` background-color: ${item.maMauTrangThai}; border-color: ${item.maMauTrangThai};`"
        >
            {{ item.trangThai }}
        </div>
        <div class="row">
            <div class="xs12 pa-3 pb-0">
                <div :class="rowText">
                    <div class="mr-2" :class="'color-chieuDi'">
                        <i class="mdi mdi-calendar-clock"></i>
                    </div>
                    <div :class="textRight">
                        {{ moment(item.thoiGianXuatBen).format("HH:mm DD/MM/yyyy") }}
                    </div>
                </div>
                <div :class="rowText" class="justify-space-between">
                    <div class="row align-center">
                        <div class="mr-2" :class="'color-chieuDi'">
                            <i class="mdi mdi-card-bulleted-outline"></i>
                        </div>
                        <div :class="textRight">
                            {{ item.bienKiemSoat }} ({{ item.maSoLenh }})
                        </div>
                        <!-- <DxButton
                            styling-mode="text"
                            icon="mdi mdi-download"
                            @click="downloadImg(item)"
                        /> -->
                    </div>
                </div>
                <div class="row">
                    <div
                        :class="
                            item.idTrangThai == $t('TrangThaiLenh.ChoBenDiKy')
                                ? 'grow'
                                : 'xs12'
                        "
                    >
                        <div :class="rowText">
                            <div class="mr-2" :class="'color-chieuDi'">
                                <i class="mdi mdi-map-marker-down"></i>
                            </div>
                            <div :class="textRight">
                                {{ `${item.tenTuyen}` }}
                            </div>
                        </div>
                    </div>
                    <div
                        class="shrink mx-2"
                        style="width: 98px !important; height: 100px !important"
                        v-if="
                            item.idTrangThai == $t('TrangThaiLenh.ChoBenDiKy') ||
                            item.idTrangThai == $t('TrangThaiLenh.BenDiDaKy') ||
                            item.idTrangThai == $t('TrangThaiLenh.ChoXacNhanTraKhach')
                        "
                    >
                        <div class="row justify-center">
                            <img
                                @click="XemBanTheHienLenh(item)"
                                v-if="item && item.qrCode"
                                :src="QrCodeLink"
                                style="
                                    width: 98px !important;
                                    height: 100px !important;
                                    max-width: unset;
                                "
                                alt=""
                            />
                        </div>
                    </div>
                </div>

                <div
                    class="row align-center justify-center mt-2"
                    style="border-top: 1px solid #dadce0"
                >
                    <div class="xs12">
                        <ion-button
                            expand="full"
                            @click="QuayLaiChiTietLenh(item.idLenhDienTu)"
                            fill="clear"
                            color="warning"
                        >
                            Xem chi tiết
                        </ion-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DxButton } from "devextreme-vue";
export default {
    components: {
        DxButton,
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
        ChiTietLenhDangThucHien: { type: Object, default: {} },
    },
    data() {
        return {
            rowText: "row align-center row-text",
            rowLastText: "row align-center row-text",
            textRight: " font-medium",
        };
    },
    computed: {
        QrCodeLink() {
            if (!this.item || !this.item?.qrCode) return "#";
            // let fcolor = this.renderColorQr(this.item?.laBenDi);
            let qrdata = this.item?.qrCode;
            return (
                `https://image-charts.com/chart?` +
                [
                    "cht=qr",
                    "icqrb=FFFFFF00",
                    // "icqrf=" + fcolor,
                    "chs=88x88",
                    "choe=UTF-8",
                    "chld=L",
                    "chl=" + qrdata,
                ].join("&")
            );
        },
    },
    methods: {
        TuChoiLenh(item) {
            this.$emit("TuChoiLenh", item);
        },
        NhanLenh(item) {
            this.$emit("NhanLenh", item);
        },
        XemBanTheHienLenh(item) {
            this.$emit("XemBanTheHienLenh", item);
        },
        QuayLaiChiTietLenh(guidLenh) {
            this.$router.push({
                path: "/tabs/LenhDienTu",
                query: {
                    isChiTiet: true,
                    guidLenh: guidLenh,
                    prePath: "/Danh-Sach-Lenh-Can-Hoan-Thanh"
                },
            });
        },
        ClickXeDenBen() {
            this.$emit("ClickXeDenBen", this.item);
        },
    },
};
</script>

<style scoped>
.card-ticket {
    position: relative;
    box-shadow: 0px 2px 9px #ccc;
    overflow: hidden;
}
.row-text {
    word-break: break-word;
    white-space: break-spaces;
}

.color-chieuDi {
    color: #03a9f4;
}
.color-chieuVe {
    color: #13b000;
}
.trang-thai {
    position: absolute;
    top: 36px;
    right: -64px;
    transform: rotate(45deg);
    border-top: 1px solid;
    border-bottom: 1px solid;
    padding: 4px 0;
    width: 224px;
    text-align: center;
    z-index: 1;
    opacity: 0.8;
    color: #fff;
}
</style>
