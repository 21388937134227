<template>
    <ion-page>
        <ion-header>
            <ion-toolbar
                style="--background: #03a9f4; --color: white; --min-height: 36px"
                class="custom-toolbar"
            >
                <ion-buttons slot="start">
                    <ion-button
                        style="--padding-end: 4px; --padding-start: 4px"
                        @click="$router.push($route.query.prePath || '/tabs/LenhDienTu')"
                    >
                        <i slot="start" class="mdi mdi-chevron-left color-fff"></i>
                    </ion-button>
                </ion-buttons>
                <ion-title>LỆNH CẦN HOÀN THÀNH</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content>
            <DxList
                ref="DanhSachLenhCanHoanThanh"
                :dataSource="DanhSachLenhCanHoanThanh"
                height="calc(100% - 8px)"
                page-load-mode="nextButton"
                :hoverStateEnabled="false"
                :focusStateEnabled="false"
                :activeStateEnabled="false"
                no-data-text="Không có dữ liệu!"
                class="list-lenh-style"
                selectionMode="all"
                select-all-mode="allPages"
                keyExpr="idLenhDienTu"
                :show-selection-controls="true"
                :selectByClick="false"
                v-model:selected-item-keys="selectedItemKeys"
                :onContentReady="onContentReady"
            >
                <template #item="{ data: item }">
                    <div class="py-2 pr-3 pl-2">
                        <FrameItem :item="item" />
                    </div>
                </template>
            </DxList>

            <div
                class="row justify-end align-center px-3"
                style="
                    position: fixed;
                    bottom: 0;
                    height: 48px;
                    border-top: 1px solid #dadce0;
                "
            >
                <div class="xs12">
                    <ion-button
                        expand="full"
                        @click="LaiXeXacNhanHoanThanhNhieuChuyenDi()"
                        :disabled="selectedItemKeys.length == 0"
                        :color="selectedItemKeys.length == 0 ? 'medium' : 'primary'"
                    >
                        Hoàn thành lệnh đã chọn ({{ selectedItemKeys.length }})
                    </ion-button>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>
<script>
import { DxList } from "devextreme-vue/list";
import { DxDateBox } from "devextreme-vue/date-box";
import {
    IonPage,
    onIonViewWillEnter,
    onIonViewWillLeave,
    IonContent,
    IonButton,
} from "@ionic/vue";
import { DxButton } from "devextreme-vue/";
import FrameItem from "./components/FrameItem.vue";
import { on } from "devextreme/events";
import { mapState } from "vuex";
export default {
    components: {
        DxList,
        DxDateBox,
        DxButton,
        IonPage,
        IonContent,
        IonButton,
        FrameItem,
    },
    props: {},
    data() {
        return {
            NgayBatDau: new Date().toISOString(),
            NgayKetThuc: new Date().toISOString(),
            DanhSachLenhCanHoanThanh: [],
            selectedItemKeys: [],
        };
    },
    computed: {
        ...mapState({
            ToaDo: (state) => state.ThongTin.ToaDo,
        }),
    },
    methods: {
        onContentReady(e) {
            var itemContent = e.element.querySelectorAll(".dx-item-content");
            on(itemContent, "dxclick", function (event, extraParameters) {
                event.stopPropagation();
            });
        },
        async LaiXeXacNhanHoanThanhNhieuChuyenDi() {
            try {
                let dataPost = {
                    danhSachLenh: this.selectedItemKeys,
                    thongTinThietBi: null,
                    toaDo: this.ToaDo ? JSON.stringify(this.ToaDo) : "",
                };
                let rs = await this.$Events.RequestPost({
                    keyRequest: "LaiXeXacNhanHoanThanhNhieuChuyenDi",
                    ServicesBE: "LenhMobile",
                    url: this.$t("urlLXQuanLyLenh.LaiXeXacNhanHoanThanhNhieuChuyenDi"),
                    dataPost: dataPost,
                    msgSuccess: "Xác nhận hoàn thành nhiều chuyến đi thành công!",
                    msgError: "Xác nhận hoàn thành nhiều chuyến đi thất bại!",
                    msgCatch: "Xác nhận hoàn thành nhiều chuyến đi thất bại!",
                });

                if (rs.status) {
                    this.$router.push(this.$route.query.prePath || "/tabs/LenhDienTu");
                    localStorage.removeItem("DanhSachLenhCanHoanThanh");
                }
            } catch (error) {
                console.log("🚀 ~ error:", error);
            }
        },
    },
    created() {
        let self = this;
        onIonViewWillEnter(() => {
            self.selectedItemKeys = [];
            self.$refs.DanhSachLenhCanHoanThanh.instance.repaint();
            let data = JSON.parse(localStorage.getItem("DanhSachLenhCanHoanThanh"));
            self.DanhSachLenhCanHoanThanh = data || [];
            setTimeout(() => {
                self.selectedItemKeys = self.DanhSachLenhCanHoanThanh.map(
                    (e) => e.idLenhDienTu,
                );
            }, 300);
        });
        onIonViewWillLeave(() => {});
    },
    mounted() {},
};
</script>
<style scoped></style>
<style lang="scss" scoped></style>
